<template>
    <div class="flex justify-center items-center mt-4">
      <div class="w-1/3 h-0.5 bg-white shadow-glow mx-20"></div>
  <img class="animate-swing  w-24 h-24" src="@/assets/images/alcaldia-pto-gaitan.png">
  <div class="w-1/3 h-0.5 bg-white shadow-glow mx-20"></div>
  
</div>
</template>
<script>
export default {
  name: 'LineaDivision'
}
</script>
<style>
  
  @keyframes swing {
      0% { transform: rotate(0deg); }
      25% { transform: rotate(180deg); }
      75% { transform: rotate(-180deg); }
      100% { transform: rotate(0deg); }
  }
/* Estilo para la sombra de resplandor */
.shadow-glow {
  box-shadow: 0 0 8px white;
}
</style>