<template>
   <div class="min-h-screen flex flex-col items-center justify-center gradient-bg">
    <img class="animate-swing gradient-mask mb-8 w-128 h-auto" src="@/assets/images/zonas-wifi-pto-gaitan.png" alt="Logo">
    <div class="container mx-auto px-4">
      <LineaDivision/>
      <GoogleMap />
      <LineaInferior/>
    </div>
  </div>
</template>

<script>
import GoogleMap from './components/GoogleMap.vue'
import LineaDivision from './components/LineaDivision.vue'
import LineaInferior from './components/LineaInferior.vue'


export default {
  name: 'App',
  components: {
    GoogleMap,
    LineaDivision,
    LineaInferior

  }
}
</script>

<style>
@keyframes gradient-animation {
  0%, 100% {
    background-color: #123524; /* Yellow */
  }

}

.gradient-bg {
  animation: gradient-animation 15s infinite;
}


</style>
