<template>
    <div class="map-container h-96 border-8 border-green-700 shadow-lg overflow-hidden bg-white" ref="mapElement"></div>
  </template>
  
  <script>
  /* global google */
  
  import { Loader } from "@googlemaps/js-api-loader";
  
  export default {
    name: 'GoogleMap',
    data() {
      return {
        locations: [
          { lat: 4.310734, lng: -72.080614, title: "NODO CENTRAL (BIBLIOTECA)" },
          { lat: 4.322306, lng: -72.082000, title: "ZONA PARQUE CIMARRON" },
          { lat: 4.322250, lng: -72.084194, title: "ZONA PARQUE LA PERLA" },
          {lat: 4.310734,	lng: -72.080614,	title: "NODO CENTRAL (BIBLIOTECA)"},	
{lat: 4.322306,	lng: -72.082000,	title: "ZONA PARQUE CIMARRON"	}	,
{lat: 4.322250,	lng: -72.084194,	title: "ZONA PARQUE LA PERLA"	}	,
{lat: 4.321111,	lng: -72.084472,	title: "ZONA PARQUE VILLA AMALIA"	},	
{lat: 4.318167,	lng: -72.082444,	title: "ZONA COL LUIS CARLOS GALAN"	},	
{lat: 4.317159,	lng: -72.084679,	title: "ZONA PARQUE VILLA DE LOS ALPES"	},	
{lat: 4.316250,	lng: -72.082917,	title: "ZONA PARQUE POPULAR"	}	,
{lat: 4.316139,	lng: -72.085694,	title: "ZONA COL LUIS ANTONIO PEREZ"	}	,
{lat: 4.315306,	lng: -72.084528,	title: "ZONA PARQUE CARIMAGUA"	}	,
{lat: 4.314694,	lng: -72.084528,	title: "ZONA PARQUE LAS PALMAS"	}	,
{lat: 4.314306,	lng: -72.078556,	title: "ZONA MALECOM"	}	,
{lat: 4.312750,	lng: -72.078056,	title: "ZONA PARQUE MACARENA"},	
{lat: 4.312528,	lng: -72.079444,	title: "ZONA PARQUE CENTRO"	},	
{lat: 4.310222,	lng: -72.077917,	title: "ZONA PARQUE LA ESPERANZA"},	
{lat: 4.310056,	lng: -72.079417,	title: "ZONA COL CAMILO TORRES"},	
{lat: 4.309083,	lng: -72.079944,	title: "ZONA GIMNASIO"	}	,
{lat: 4.311694,	lng: -72.083278,	title: "ZONA PARQUE MANACACIAS"	},	
{lat: 4.312083,	lng: -72.084861,	title: "ZONA COL JORGE ELIECER GAITAN"	},	
{lat: 4.313112,	lng: -72.103239,	title: "ZONA BATEAS 1 COLEGIO BATEAS"	}	,
{lat: 4.311517,	lng: -72.106062,	title: "ZONA BATEAS 2 PARQUE "}	,
{lat: 4.308376,	lng: -72.109005,	title: "ZONA BATEAS 3 PARQUE"	}	,
{lat: 4.306533,	lng: -72.083021,	title: "ZONA PARQUE 4 TRAMPOLIN	"},	
{lat: 4.325820,	lng: -72.084360,	title: "ZONA CORPOMECAVI 1 PARQUE" },	
{lat: 4.325637,	lng: -72.082011,	title: "ZONA CORPOMECAVI 2 PARQUE	"},	
{lat: 4.328080,	lng: -72.080320,	title: "ZONA CORPOMECAVI 3 PARQUE	"},
        ],
        markerIcon: require('@/assets/images/wifi_pto_gaitan.png'), // Usando require para cargar la imagen
      userMarkerIcon: require('@/assets/images/pto_gaitan_marker_here.png'), // Imagen para el marcador del usuario
      userLocation: null,
      directionsService: null,
      directionsRenderer: null,
      map: null,
    };
  },
  mounted() {
    const loader = new Loader({
      apiKey: "AIzaSyDXJEoO1qxM6Ta5AqPpIpquX-5DUriet54",
      version: "weekly"
    });

    loader.load().then(() => {
      this.map = new google.maps.Map(this.$refs.mapElement, {
        center: { lat: 4.3155125, lng: -72.0905687 },
        zoom: 15
      });

      const markerSize = new google.maps.Size(30, 30); // Ajusta el tamaño aquí (ancho, alto)
      this.directionsService = new google.maps.DirectionsService();
      this.directionsRenderer = new google.maps.DirectionsRenderer();
      this.directionsRenderer.setMap(this.map);

      // Colocar los marcadores iniciales
      this.locations.forEach(location => {
        const marker = new google.maps.Marker({
          position: { lat: location.lat, lng: location.lng },
          map: this.map,
          title: location.title,
          icon: {
            url: this.markerIcon,
            scaledSize: markerSize, // Usando el tamaño ajustado
          },
        });

        const infoWindowContent = document.createElement('div');
        infoWindowContent.innerHTML = `
          <div>
            <h3>${location.title}</h3>
            <button id="route-btn-${location.lat}-${location.lng}" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Cómo llegar</button>
          </div>
        `;

        const infoWindow = new google.maps.InfoWindow({
          content: infoWindowContent
        });

        marker.addListener('click', () => {
          infoWindow.open(this.map, marker);
        });

        // Añadir evento al botón después de abrir el InfoWindow
        infoWindow.addListener('domready', () => {
          document.getElementById(`route-btn-${location.lat}-${location.lng}`).addEventListener('click', () => {
            this.showRoute(location.lat, location.lng);
          });
        });
      });

      // Agregar funcionalidad de geolocalización
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          this.userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          new google.maps.Marker({
            position: this.userLocation,
            map: this.map,
            title: "Usted está aquí",
            icon: {
              url: this.userMarkerIcon,
              scaledSize: markerSize, // Usando el tamaño ajustado para el marcador del usuario
            },
          });

          this.map.setCenter(this.userLocation);
        }, () => {
          console.error("Error: The Geolocation service failed.");
        });
      } else {
        console.error("Error: Your browser doesn't support geolocation.");
      }
    });
  },
  methods: {
    showRoute(lat, lng) {
      if (this.userLocation) {
        const request = {
          origin: this.userLocation,
          destination: { lat, lng },
          travelMode: 'DRIVING'
        };

        this.directionsService.route(request, (result, status) => {
          if (status === 'OK') {
            this.directionsRenderer.setDirections(result);
          } else {
            console.error('Directions request failed due to ' + status);
          }
        });
      } else {
        console.error('User location is not available.');
      }
    }
  }
}
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 400px;
}
.route-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}
</style>